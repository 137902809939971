import Select, { components } from "react-select";
import "./CustomDropdown.css";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useEffect, useRef } from "react";

function CustomDropdown(props) {
  const CustomControl = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        {props.hasValue ? (
          <div
            className="floating-label"
            style={{ marginTop: hasSummary ? "9px" : "" }}
          >
            {props.selectProps.placeholder}
          </div>
        ) : null}
        {hasSummary && props.hasValue ? (
          <div className="floating-label-below">{summaryText}</div>
        ) : null}
        {children}
      </components.Control>
    );
  };

  const isCustomOptions = props?.customOptions ?? false;
  const noOptionsIcons = props?.noOptionsIcons ?? false;
  const hasSummary = props?.hasSummary ?? false;
  const summaryText = props?.value?.summary ?? null;
  const value = props?.value ?? false;
  const mergedMenu = props?.mergedMenu ?? false;
  const shouldHighlight = props?.shouldHighlight ?? true;
  const placeholder = props?.placeholder ?? "Select...";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".option") && props.menuOpenState) {
        props.onMenuClose && props.onMenuClose();
        props.setMenuOpenState && props.setMenuOpenState(false);
      }
    };

    if (props.menuOpenState) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [props.menuOpenState]);

  const selectBackground = {
    ...props?.styles,
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected && !mergedMenu ? "var(--blue-opacity10)" : "inherit",
      color: state.isSelected ? "var(--main-blue)" : "inherit",
      margin: "4px 0px",
    }),
    menuList: (provided) => ({
      ...provided,
      minHeight: props.maxHeight,
      height: props.maxHeight,
    }),
  };

  function dropdownHasValue(value) {
    return value !== null && (!Array.isArray(value) || value.length);
  } // By default filters always have values because unselected means all is selected

  const CustomOption = ({ children, ...props }) => {
    const data = props.data;
    const index = props.options.findIndex(
      (option) => option.value === data.value,
    );
    const isFirstItem = index === 0;
    const separator = data?.separator ?? false;
    let classes = props.getClassNames() || "";
    classes = !!separator ? `${classes} option-separator` : classes;
    props.isSelected = !!data && data?.isSelected;
    props.isDisabled = !!data && data?.isDisabled;
    props.isFocused = !!data && data?.isFocused;

    if (mergedMenu) {
      return (
        <components.Option {...props} className={classes}>
          {isFirstItem && (
            <div className="merged-menu-label">{placeholder}</div>
          )}
          {isFirstItem && (
            <div className="merged-menu-chevron">
              <FiChevronUp
                alt="chevron up"
                color={props.blueChevron ? "#2A00FF" : "#707070"}
                size="24px"
              />
            </div>
          )}
          <span className="option-wrapper">
            <span className={noOptionsIcons ? "" : "option-icon"}>
              {data.icon}
            </span>
            {data?.description ? (
              <span
                className="option-main"
                style={{ marginLeft: mergedMenu ? "-1px" : "" }}
              >
                {children}
                <span className="option-description">{data?.description}</span>
              </span>
            ) : (
              children
            )}
          </span>
        </components.Option>
      );
    }

    if (!!isCustomOptions) {
      return (
        <components.Option {...props} className={classes}>
          <span className="option-wrapper">
            <span className="option-icon">{data.icon}</span>
            {data?.description ? (
              <span className="option-main">
                {children}
                <span className="option-description">{data?.description}</span>
              </span>
            ) : (
              children
            )}
          </span>
        </components.Option>
      );
    }
    return <components.Option {...props}>{children}</components.Option>;
  };

  return (
    <div>
      <Select
        onBlur={props.onBlur}
        maxHeight={props.maxHeight}
        options={props.options}
        isDisabled={props.disabled}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        unstyled={props.unstyled}
        styles={props?.styles ?? selectBackground}
        menuPlacement={props.menuPlacement ?? "bottom"}
        classNames={{
          control: () => {
            let classes = "dropdown-control";
            if (hasSummary && value) {
              classes += " dropdown-control-has-summary";
            }

            if (dropdownHasValue(props.value) && !props.components?.Control) {
              classes += props.controlChips
                ? " dropdown-control-has-value-chip"
                : " dropdown-control-has-value";
            }

            classes = !!props.className
              ? (classes += ` ${props.className}`)
              : classes;

            return classes;
          },
          menu: () =>
            props.mergedMenu
              ? value
                ? "merged-menu-has-value"
                : "merged-menu"
              : "menu",
          option: () => (shouldHighlight ? "option" : "option no-highlight"),
          ...props.classNames,
        }}
        onMenuOpen={
          props.setMenuOpenState
            ? () => props.setMenuOpenState(true)
            : props.onMenuOpen
        }
        onMenuClose={
          props.setMenuOpenState
            ? () => props.setMenuOpenState(false)
            : props.onMenuClose
        }
        isMulti={props.isMulti}
        hideSelectedOptions={props.hideSelectedOptions}
        menuIsOpen={props?.menuIsOpen ?? props.menuOpenState}
        closeMenuOnSelect={props.closeMenuOnSelect}
        isClearable={props.isClearable}
        blurInputOnSelect={props.blurInputOnSelect}
        isLabelType={props.isLabelType}
        blueChevron={props.blueChevron}
        controlChips={props.controlChips}
        hasSummary={props.hasSummary}
        isSearchable={props.isSearchable ?? true}
        mergedMenu={props.mergedMenu ?? false}
        components={{
          Control: CustomControl,
          Option: CustomOption,
          SingleValue: (svprops) => (
            <components.SingleValue
              {...svprops}
              className={`select-selected-option ${
                props.value ? "visible" : "hidden"
              }`}
            />
          ),
          DropdownIndicator: (dropdownprops) => (
            <components.DropdownIndicator
              className={
                dropdownHasValue(props.value) &&
                !props.isLabelType &&
                !props.controlChips
                  ? "chevron-value-offset"
                  : ""
              }
              {...dropdownprops}
            >
              {props.noIcon ? (
                <div />
              ) : props.menuOpenState ? (
                <FiChevronUp
                  alt="chevron up"
                  color={props.blueChevron ? "#2A00FF" : "#707070"}
                  size="24px"
                />
              ) : (
                <FiChevronDown
                  alt="chevron down"
                  color={props.blueChevron ? "#2A00FF" : "#707070"}
                  size="24px"
                />
              )}
            </components.DropdownIndicator>
          ),
          ...props.components,
        }}
      />
    </div>
  );
}
export default CustomDropdown;
