import { useContext, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { UserInfoContext } from "../../contexts";
import NotificationPopup from "../NotificationPopup/NotificationPopup";
import {
  getPersonas,
  savePersonaToDb,
  uploadFileToFirestore,
} from "../../helpers";
import { Spinner } from "react-bootstrap";
import Modal from "react-modal";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import CustomInput from "../CustomInput/CustomInput";
import { toast } from "react-toastify";
import CustomNavbar from "../reusables/CustomNavbar/CustomNavbar";

export function PersonasContainer({
  selectedProject,
  selectedPersona,
  setSelectedPersona,
  loading,
  setLoading,
  ...props
}) {
  const userInfo = useContext(UserInfoContext);
  const [activeTab, setActiveTab] = useState("summary");
  const tabItems = [
    { name: "summary", label: "Summary", link: "/personas/summary" },
    // { name: "tracking", label: "Tracking", link: "/personas/tracking" },
    // {
    //   name: "importance",
    //   label: "Persona Importance",
    //   link: "/personas/importance",
    // },
  ];

  const [personas, setPersonas] = useState(null);
  const [savingPersona, setSavingPersona] = useState(false);

  async function fetchPersonas(pid) {
    return await getPersonas(pid);
  }

  async function savePersona(name, type, pfp, file) {
    return new Promise(async (resolve, reject) => {
      try {
        if (type === "primary") {
          const existingPrimary = personas.find(
            (persona) =>
              persona.type === "primary" && persona.id !== selectedPersona.id,
          );
          if (existingPrimary) {
            await savePersonaToDb(
              existingPrimary.id,
              existingPrimary.name,
              "secondary",
              existingPrimary.pfp,
            );
          }
        }

        let imageLink = null;
        if (pfp && file) {
          imageLink = await uploadFileToFirestore(
            "projects/" + selectedProject.value,
            file,
          );
        }

        await savePersonaToDb(selectedPersona.id, name, type, imageLink);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  function sortPersonas(personas) {
    const typeOrder = {
      primary: 1,
      secondary: 2,
      tertiary: 3,
    };

    return personas.sort((a, b) => {
      const typeA = typeOrder[a.type] ?? Infinity;
      const typeB = typeOrder[b.type] ?? Infinity;
      if (typeA !== typeB) {
        return typeA - typeB;
      } else {
        return b.n_visitors - a.n_visitors;
      }
    });
  }

  // function calculatePersonaPercentages(personas) {
  //   const totalTraffic = personas.reduce(
  //     (sum, persona) => sum + (parseInt(persona.count, 10) || 0),
  //     0,
  //   );

  //   let accumulatedTraffic = 0;

  //   const updatedPersonas = personas.map((p, index) => {
  //     let traffic = p.count
  //       ? Math.round((parseInt(p.count, 10) / totalTraffic) * 100)
  //       : 0;

  //     if (index === personas.length - 1) {
  //       traffic = 100 - accumulatedTraffic;
  //     } else {
  //       accumulatedTraffic += traffic;
  //     }

  //     return {
  //       ...p,
  //       traffic,
  //     };
  //   });

  //   return updatedPersonas;
  // }

  const [modifyModalOpen, setModifyModalOpen] = useState(false);

  const ModifyModal = ({ selectedPersona }) => {
    const [isTypeMenuOpen, setIsTypeMenuOpen] = useState(false);
    const options = [
      {
        label: "Primary",
        value: "primary",
        summary: "Main user, core focus of design.",
        description:
          "The main user for whom the product is designed, with needs and goals that must be fully met.",
      },
      {
        label: "Secondary",
        value: "secondary",
        summary: "Less prioritized user",
        description:
          "A user who shares some goals with the primary persona but has specific, less prioritized needs.",
      },
      {
        label: "Tertiary / Complentary / Other",
        value: "tertiary",
        summary: "Least prioritized user",
        description:
          "Not the main user, but their goals and needs can be considered without conflicting with those of the primary persona.",
      },
    ];

    const preselectedType = options.find(
      (option) => option.value === selectedPersona?.type,
    );
    const [selectedType, setSelectedType] = useState(preselectedType ?? null);
    const [name, setName] = useState(selectedPersona?.name ?? null);
    const [pfp, setPfp] = useState(null);
    const [file, setFile] = useState(null);

    const onSelectImage = (e) => {
      if (!e.target.files || e.target.files.length === 0) {
        return;
      }

      if (!e.target.files[0]) {
        return;
      }

      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setPfp(imageUrl);
      setFile(e.target.files[0]);
      return () => URL.revokeObjectURL(imageUrl);
    };

    return (
      <Modal
        isOpen={modifyModalOpen}
        onRequestClose={() => setModifyModalOpen(false)}
        contentLabel="Modify persona"
        className="personas-modify-modal custom-modal"
        overlayClassName="modal-overlay-blue"
        appElement={document.getElementById("root")}
      >
        <div className="filters-modal-container">
          <div className="">
            <div className="modal-header__title fs-24 fw-500 vhc mb-32">
              Modify Persona profile
            </div>
            <i
              className="fa-solid fa-xmark close-icon"
              onClick={() => setModifyModalOpen(false)}
            ></i>

            <div className="vhc mb-32">
              <img
                style={{
                  width: "90px",
                  height: "90px",
                  borderRadius: "50%",
                }}
                src={pfp ?? selectedPersona?.pfp ?? ""}
              ></img>
            </div>

            <div className="vhc mb-10 persona-367-width">
              <div>
                <CustomInput
                  placeholder="Name"
                  maxLength={40}
                  containerClassName="observation-text-input-container delete-width-input"
                  inputClassNames="delete-width-input"
                  className={name === "" ? "active" : ""}
                  valueState={name}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  paddingRight={"70px"}
                />
                <div
                  className="fs-14 fw-500 fc-grey input-limit-counter"
                  style={{
                    top: name === "" ? "245px" : "265px",
                    transition: "top 0.2s ease",
                  }}
                >
                  {name.length}/40
                </div>
              </div>
            </div>

            <div className="vhc fw-500 fs-16 fc-grey mb-24 persona-367-width">
              <div>
                Choose a name that really represents the strong characteristics
                of your persona.
              </div>
            </div>
            <div
              className="no-margins-or-padding"
              style={{ marginBottom: "0px" }}
            >
              <div className="vhc mb-30 persona-367-width">
                <div>
                  <CustomDropdown
                    customOptions
                    noOptionsIcons
                    hasSummary
                    isSearchable={false}
                    mergedMenu
                    menuPlacement={"auto"}
                    shouldHighlight={false}
                    options={options?.map((o) => ({
                      ...o,
                      isSelected: o.value === selectedPersona.type,
                      icon: <></>,
                    }))}
                    onChange={(option) => setSelectedType(option)}
                    placeholder={"Kind of persona"}
                    value={selectedType}
                    unstyled
                    setMenuOpenState={(res) => setIsTypeMenuOpen(res)}
                    menuOpenState={isTypeMenuOpen}
                  />
                </div>
              </div>
            </div>
            <div className="vhc mb-50">
              <div
                className="persona-367-width fs-14 fc-blue underline"
                onClick={() => {
                  if (userInfo.role === "client") {
                    document.getElementById(`modify-pfp`).click();
                  } else {
                    toast.warn("Only main client can modify the image");
                  }
                }}
              >
                Change picture
              </div>

              <input
                type="file"
                id={"modify-pfp"}
                style={{ display: "none" }}
                onChange={(e) => {
                  onSelectImage(e);
                }}
                accept=".jpeg, .jpg"
              ></input>
            </div>

            <div className="vhc mb-75 persona-367-width">
              <div>
                <button
                  disabled={!name || !selectedType || savingPersona}
                  className="btn-primary w-100 fw-700"
                  onClick={() => {
                    setModifyModalOpen(false);
                    setSavingPersona(true);
                    savePersona(name, selectedType.value, pfp, file).then(
                      () => {
                        setPersonas((prevPersonas) => {
                          let updatedPersonas = prevPersonas.map((persona) => {
                            if (persona.id === selectedPersona.id) {
                              return {
                                ...persona,
                                name: name,
                                type: selectedType.value,
                                ...(pfp && { pfp }),
                              };
                            } else if (
                              selectedType.value === "primary" &&
                              persona.type === "primary"
                            ) {
                              return {
                                ...persona,
                                type: "secondary",
                              };
                            } else {
                              return persona;
                            }
                          });

                          updatedPersonas = sortPersonas(updatedPersonas);

                          const updatedSelectedPersona = updatedPersonas.find(
                            (p) => p.id === selectedPersona.id,
                          );
                          setSelectedPersona(updatedSelectedPersona);
                          return updatedPersonas;
                        });

                        setSavingPersona(false);
                      },
                    );
                  }}
                >
                  {savingPersona ? (
                    <Spinner style={{ width: "25px", height: "25px" }} />
                  ) : (
                    <>Confirm</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  useEffect(() => {
    if (!selectedProject) {
      setLoading(false);
      return;
    }
    setLoading(true);
    fetchPersonas(selectedProject.id ?? selectedProject.value).then(
      (personas) => {
        if (personas && personas.length > 0) {
          let p = personas;
          p = sortPersonas(p);
          setPersonas(p);
          setSelectedPersona(p[0]);
        } else {
          setPersonas([]);
          setSelectedPersona(null);
        }
        setLoading(false);
      },
    );
  }, [selectedProject]);

  return (
    <div style={{ marginLeft: "-80px" }}>
      {modifyModalOpen && <ModifyModal selectedPersona={selectedPersona} />}
      <div className="personas-purple-box"></div>
      <div style={{ position: "absolute", top: "32px", right: "32px" }}>
        <div className="d-flex justify-content-end align-items-center">
          <NotificationPopup
            setActiveMenuItem={props.setActiveMenuItem}
            userInfo={userInfo}
          />
        </div>
      </div>
      <div
        style={{
          height: "383px",
        }}
      >
        <div className="fs-20 fw-700 fc-black mb-10 vhc">Personas</div>
        <div>
          {loading ? (
            <div className="hc pt-50">
              <Spinner style={{ height: "150px", width: "150px" }} />
            </div>
          ) : !selectedProject ? (
            <h3 className="vhc">No project selected</h3>
          ) : !personas || personas.length === 0 ? (
            <h3 className="vhc">No personas available</h3>
          ) : (
            <>
              <div className="fs 16 fw-500 fc-grey vhc">
                LUXIFER AI detects {personas.length} persona
                {personas.length > 1 ? "s" : ""}.
              </div>
              <div className="mt-50 d-flex hc">
                {personas.map((p, i) => {
                  const showSeparator =
                    i === 0 &&
                    personas.length > 1 &&
                    personas.some((persona) => persona.type === "primary");
                  const lastItem = i === personas.length - 1;
                  const singleItem = personas.length === 1;

                  const show1 = i === 0 && p.type === "primary";
                  let show2 =
                    i ===
                    personas.findIndex(
                      (persona) => persona.type === "secondary",
                    );
                  let show3 =
                    i ===
                    personas.findIndex(
                      (persona) => persona.type === "tertiary",
                    );

                  return (
                    <>
                      <div
                        key={i}
                        style={{ width: "150px" }}
                        className={
                          showSeparator || singleItem || lastItem ? "" : "mr-24"
                        }
                        onClick={() => {
                          if (!modifyModalOpen) {
                            setSelectedPersona(p);
                          }
                        }}
                      >
                        <div className="persona-container">
                          {show1 && (
                            <i className="fa-regular fa-circle-1 persona-num"></i>
                          )}
                          {show2 && (
                            <i className="fa-regular fa-circle-2 persona-num"></i>
                          )}
                          {show3 && (
                            <i className="fa-regular fa-circle-3 persona-num"></i>
                          )}
                          {p === selectedPersona && (
                            <div className="persona-selected-circle"></div>
                          )}
                          {!modifyModalOpen && (
                            <div
                              onClick={() => {
                                if (
                                  !modifyModalOpen &&
                                  userInfo.role !== "reader"
                                ) {
                                  setModifyModalOpen(true);
                                } else {
                                  toast.warn("Readers cannot modify personas");
                                }
                              }}
                              className="personas-edit fc-white vhc"
                            >
                              <div>
                                <i className="fa-regular fa-camera-retro fs-20 vhc"></i>
                                <div className="fs-14 fw-500">Modify</div>
                              </div>
                            </div>
                          )}
                          <img
                            style={{
                              width: "90px",
                              height: "90px",
                              borderRadius: "50%",
                            }}
                            src={p.pfp}
                          ></img>
                          <div
                            style={{ marginLeft: "-30px" }}
                            className={`${
                              p === selectedPersona
                                ? "fc-blue fw-700"
                                : "fc-black2 fw-500"
                            }  mt-25`}
                          >
                            <div
                              style={{ width: "150px" }}
                              className="txt-center fs-16 wrapword limit-5-lines"
                            >
                              {p.name}
                            </div>
                            <div
                              style={{ width: "150px" }}
                              className={`txt-center ${
                                i === 0 ? "fs-16" : "fs-14"
                              } fw-500`}
                            >
                              {p.percentage_visitors
                                .toString()
                                .replace(".", ",")}
                              %
                            </div>
                          </div>
                        </div>
                      </div>
                      {showSeparator && (
                        <div className="persona-primary-separator"></div>
                      )}
                    </>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="mt-28">
        <CustomNavbar
          tabItems={tabItems}
          centered={true}
          hasHr={false}
          changeOnClick={true}
        />
      </div>

      <Outlet />
    </div>
  );
}
