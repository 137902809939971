import { auth, db } from "../../firebase";
import { useEffect, useState } from "react";
import "./ManageProjects.css";
import "../UxChecklist/UxChecklist.css";
import CustomInput from "../CustomInput/CustomInput";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  getUserInformation,
  getUserProjects,
  urlIsValid,
  uploadFileToFirestore,
  verifyGoogleAnalytics,
  verifyMatomo,
  getClientOrganizations,
  changeProjectOrg,
  createNewOrg,
  AddOrganizationModal,
  handleDeleteProject,
  updateMatomoWebsite,
} from "../../helpers";
import { Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import { doc, updateDoc } from "firebase/firestore";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Modal from "react-modal";
import { BsXCircle } from "react-icons/bs";
import check from "../../assets/check.png";

function ManageProjectsContent({
  userInfo,
  setDeleteProjectConfirmModalIsOpen,
  savingToDb,
  setSavingToDb,
  projects,
  setProjectToDelete,
  orgOptions,
  setAddOrgModalIsOpen,
  setSelectedProject,
}) {
  const [projectOpen, setProjectOpen] = useState([]);
  const [projectStates, setProjectStates] = useState(null);

  const [projectsLoading, setProjectsLoading] = useState(true);

  const location = useLocation();
  const [searchParams, setSearchParams] = useState(
    new URLSearchParams(location.search),
  );
  const [pid, setPid] = useState(searchParams.get("pid"));
  const [tab, setTab] = useState(searchParams.get("tab"));

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSearchParams(params);
    setPid(params.get("pid") ?? "");
    setTab(params.get("tab") ?? "");
  }, [location]);

  useEffect(() => {
    if (!projectsLoading && pid) {
      const element = document.getElementById(pid);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [projectsLoading, pid]);

  const currencyOptions = [
    { value: "$", label: "USD - $" },
    { value: "€", label: "EURO - €" },
  ];

  const sensitivityOptions = [
    { value: "high", label: "High" },
    { value: "medium", label: "Medium" },
    { value: "low", label: "Low" },
  ];

  const tabItems = [
    {
      label: "General",
    },
    // {
    //   label: "Analytics & Limits",
    // },
    {
      label: "Commercial value",
    },
    {
      label: "Metric alerts",
    },
  ];

  const [industryOptions] = useState([
    {
      label: (
        <>
          <i className="fa-regular fa-rectangle-ad mr-8"></i>
          Media
        </>
      ),
      value: "media",
    },
    {
      label: (
        <>
          <i className="fa-regular fa-washing-machine mr-8"></i>
          Retail
        </>
      ),
      value: "retail",
    },
    {
      label: (
        <>
          <i className="fa-regular fa-code mr-8"></i>
          Software
        </>
      ),
      value: "software",
    },
    {
      label: (
        <>
          <i className="fa-regular fa-tree-city mr-8"></i>
          Government & Administration
        </>
      ),
      value: "government_and_administration",
    },
    {
      label: (
        <>
          <i className="fa-regular fa-user-bounty-hunter mr-8"></i>
          Head Hunter/ Jobs
        </>
      ),
      value: "headhunter_jobs",
    },
    {
      label: (
        <>
          <i className="fa-regular fa-ban mr-8"></i>
          N/A
        </>
      ),
      value: "",
    },
  ]);

  const getIndustryOption = (industryValue) => {
    return industryOptions.find((opt) => opt.value === industryValue);
  };

  const onSelectImage = (e, i) => {
    if (!e.target.files || e.target.files.length === 0) {
      handleProjectStateChange(undefined, "image", i);
      handleProjectStateChange(false, "imageChanged", i);
      return;
    }

    if (!e.target.files[0]) {
      handleProjectStateChange(undefined, "image", i);
      handleProjectStateChange(false, "imageChanged", i);
      return;
    }

    const imageUrl = URL.createObjectURL(e.target.files[0]);
    handleProjectStateChange(imageUrl, "image", i);
    handleProjectStateChange(e.target.files[0], "imageFile", i);
    handleProjectStateChange(true, "imageChanged", i);

    // Free memory whenever this component is unmounted
    return () => URL.revokeObjectURL(imageUrl);
  };

  useEffect(() => {
    if (projects && projectOpen.length === 0) {
      setProjectOpen(
        projects.map((project) => (pid ? project.value === pid : false)),
      );
      setProjectStates(
        projects.map((project) => ({
          ...project,
          urlsChanged: false,
          urlsValid: true,
          analyticsChanged: false,

          nameChanged: false,
          purchaseValueChanged: false,
          sensitivityValueChanged: false,
          imageChanged: false,
          industryChanged: false,
          industryMenuIsOpen: false,
          showIndustryDropdown: false,
          industry: project.industry ?? null,
          selectedIndustry:
            industryOptions.find((opt) => opt.value === project.industry) ||
            null,

          activeTab: tab ? tab : "General",

          orgMenuIsOpen: false,
          orgChanged: project.orgChanged ?? false,

          currencyMenuIsOpen: false,
          sensitivityMenuIsOpen: false,
          selectedCurrency:
            currencyOptions.find(
              (option) => option.value === project?.currency,
            ) ?? null,
          selectedSensitivity:
            sensitivityOptions.find(
              (option) => option.value === project?.sensitivity,
            ) ?? sensitivityOptions[1],
        })),
      );
      setProjectsLoading(false);
    }
  }, [projects, pid]);

  // Entire project section
  const toggleProjectOpenIndex = (index) => {
    setProjectOpen(
      projectOpen.map((isOpen, i) => (i === index ? !isOpen : isOpen)),
    );
  };

  const saveProjectToDb = async (
    projectId,
    fields,
    unblockSaving = true,
    showToast = true,
  ) => {
    setSavingToDb(true);
    const projectDoc = doc(db, "projects", projectId);
    await updateDoc(projectDoc, fields).then(() => {
      if (unblockSaving) {
        setSavingToDb(false);
      }
      if (showToast) {
        toast.success("Saved!");
      }
    });
  };

  const handleLinksSaveClick = async (i) => {
    const projectState = projectStates[i];
    // Handle case where other urls were never added to project during creation
    const dataToSave = projectState.otherUrls
      ? {
          rootUrl: projectState.rootUrl,
          otherUrls: projectState.otherUrls,
        }
      : {
          rootUrl: projectState.rootUrl,
        };

    await saveProjectToDb(projectState?.value, dataToSave);

    await updateMatomoWebsite({
      idSite: projectState.matomoId,
      rootUrl: projectState.rootUrl,
    });

    handleProjectStateChange(false, "urlsChanged", i);
  };

  const handleAnalyticsSaveClickMatomo = async (i) => {
    handleProjectStateChange(false, "validMatomo", i);
    await saveProjectToDb(
      projectStates[i]?.value,
      {
        matomoId: projectStates[i].matomoId,
      },
      false,
      false,
    );

    const verifyGoogleAnalyticsResponse = await verifyMatomo({
      params: {
        siteId: projectStates[i].matomoId,
      },
    });

    let validMatomoValue = -1;
    if (verifyGoogleAnalyticsResponse && verifyGoogleAnalyticsResponse !== -1) {
      validMatomoValue = 1;
    }

    await saveProjectToDb(projectStates[i]?.value, {
      validMatomo: validMatomoValue,
    });

    handleProjectStateChange(validMatomoValue, "validMatomo", i);
    setSavingToDb(false);
    handleProjectStateChange(false, "analyticsChanged", i);
  };

  const handleAnalyticsSaveClickGA4 = async (i) => {
    handleProjectStateChange(false, "validGA4", i);
    await saveProjectToDb(
      projectStates[i]?.value,
      {
        googleAnalyticsId: projectStates[i].googleAnalyticsId,
      },
      false,
      false,
    );

    const verifyGoogleAnalyticsResponse = await verifyGoogleAnalytics({
      projectId: projectStates[i].googleAnalyticsId,
    });

    // Invalid Id check
    let validGA4Value = -1;

    if (
      verifyGoogleAnalyticsResponse &&
      verifyGoogleAnalyticsResponse !==
        "No access to project, verify the account is authorized." &&
      verifyGoogleAnalyticsResponse !== "Invalid Project ID"
    ) {
      // Valid
      validGA4Value = 1;
    }

    await saveProjectToDb(projectStates[i]?.value, {
      validGA4: validGA4Value,
    });

    handleProjectStateChange(validGA4Value, "validGA4", i);
    setSavingToDb(false);
    handleProjectStateChange(false, "analyticsChanged", i);
  };

  const handlePurchaseValueSaveClick = async (i) => {
    await saveProjectToDb(projectStates[i]?.value, {
      purchaseValue: projectStates[i].purchaseValue,
      currency: projectStates[i].selectedCurrency?.value,
    });
    handleProjectStateChange(false, "purchaseValueChanged", i);
  };

  const handleSensitivityValueSaveClick = async (i) => {
    await saveProjectToDb(projectStates[i]?.value, {
      sensitivity: projectStates[i].selectedSensitivity?.value,
    });
    handleProjectStateChange(false, "sensitivityValueChanged", i);
  };

  const handleNameClick = async (i) => {
    await saveProjectToDb(projectStates[i]?.value, {
      name: projectStates[i].name,
    });
    handleProjectStateChange(false, "nameChanged", i);
  };

  const handleOrgClick = async (i) => {
    setSavingToDb(true);
    if (!projectStates[i]?.id || !projectStates[i]?.selectedOrg?.value) {
      toast.error("Error updating organization!");
      setSavingToDb(false);
      return;
    }
    await changeProjectOrg(
      projectStates[i]?.id,
      projectStates[i].selectedOrg?.value,
      projectStates[i]?.orgId,
    );
    setSavingToDb(false);
    handleProjectStateChange(false, "orgChanged", i);
    toast.success("Organization updated!");
  };

  const handleImageClick = async (i) => {
    let imageLink =
      "https://firebasestorage.googleapis.com/v0/b/ux-pro.appspot.com/o/users%2Fblack-logo.png?alt=media&token=e05881ad-fe52-4cbb-9cb5-0e366b03a8f6";

    if (projectStates[i].imageFile) {
      imageLink = await uploadFileToFirestore(
        "projects/" + projectStates[i]?.value,
        projectStates[i].imageFile,
      );
    }

    await saveProjectToDb(projectStates[i]?.value, {
      imageLink: imageLink,
    });
  };

  function checkIfUrlsValid(i) {
    setProjectStates((prevList) =>
      prevList.map((projectStates, j) => {
        if (i === j) {
          const isValid =
            urlIsValid(projectStates.rootUrl) &&
            (projectStates.otherUrls?.length
              ? projectStates.otherUrls.every((link) => urlIsValid(link))
              : true);

          const basedOnMainUrl =
            projectStates.otherUrls && projectStates.otherUrls.length > 0
              ? projectStates.otherUrls.every((link) => {
                  try {
                    const mainUrl = new URL(projectStates.rootUrl);
                    const linkUrl = new URL(
                      link.startsWith("http") ? link : `https://${link}`,
                    );
                    return linkUrl.origin === mainUrl.origin;
                  } catch (e) {
                    return false;
                  }
                })
              : true;

          return {
            ...projectStates,
            urlsValid: isValid && basedOnMainUrl,
          };
        }
        return projectStates;
      }),
    );
  }

  function handleOtherUrlChange(value, i, j) {
    setProjectStates((prevList) =>
      prevList.map((projectStates, k) => {
        if (i === k) {
          return {
            ...projectStates,
            urlsChanged: true,
            otherUrls: projectStates.otherUrls?.map((otherUrl, l) =>
              l === j ? value : otherUrl,
            ),
          };
        }
        return projectStates;
      }),
    );
  }

  function handleRemoveOtherUrl(i, j) {
    setProjectStates((prevList) =>
      prevList.map((projectStates, k) => {
        if (i === k) {
          return {
            ...projectStates,
            urlsChanged: true,
            otherUrls: projectStates.otherUrls?.filter((_, l) => l !== j),
          };
        }
        return projectStates;
      }),
    );
  }

  function handleProjectStateChange(value, field, i) {
    setProjectStates((prevList) =>
      prevList.map((states, j) => {
        if (i === j) {
          return {
            ...states,
            [field]: value,
          };
        }
        return states;
      }),
    );
  }

  function addOtherUrlsToProject(i) {
    setProjectStates((prevList) =>
      prevList.map((projectStates, j) => {
        if (i === j) {
          return {
            ...projectStates,
            urlsChanged: true,
            urlsValid: false,
            otherUrls: projectStates.otherUrls
              ? [...projectStates.otherUrls, ""]
              : [""],
          };
        }
        return projectStates;
      }),
    );
  }

  const handleIndustrySaveClick = async (i) => {
    setSavingToDb(true);
    const projectState = projectStates[i];
    try {
      await saveProjectToDb(projectState.value, {
        industry: projectState.selectedIndustry.value,
      });
      handleProjectStateChange(
        projectState.selectedIndustry.value,
        "industry",
        i,
      );
      handleProjectStateChange(false, "industryChanged", i);
      handleProjectStateChange(false, "showIndustryDropdown", i);
    } catch (error) {
      toast.error("Failed to update industry. Please try again.");
    } finally {
      setSavingToDb(false);
    }
  };

  return (
    <div style={{ height: "100vh", display: "flex", flexGrow: 1 }}>
      <div className="profile-main-content">
        <div style={{ marginLeft: "50.36px", paddingBottom: "500px" }}>
          <div style={{ marginTop: "35.5px" }}>
            <a href="/">
              <div
                style={{
                  width: "158px",
                  height: "24px",
                  textAlign: "center",
                }}
                className="fs-14 fw-700 fc-black"
              >
                <FiArrowLeft
                  color="#000"
                  size="24px"
                  style={{ marginRight: "10px", marginBottom: "2px" }}
                />
                Back to Dashboard
              </div>
            </a>
            <div
              style={{ width: "194px", height: "24px", marginTop: "61.55px" }}
              className="fs-24 fw-700 lh-243 "
            >
              Manage projects
            </div>
          </div>

          {projectsLoading ? (
            <div style={{ paddingTop: "50px", paddingLeft: "25px" }}>
              <Spinner style={{ width: "100px", height: "100px" }} />
            </div>
          ) : (
            <div style={{ marginTop: "68px" }}>
              {projectStates?.map((p, i) => (
                <div key={i} id={p.value} style={{ paddingTop: "32px" }}>
                  <div
                    className="row"
                    style={{
                      margin: "0",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <img
                      src={p.image}
                      className="img-keep-ratio"
                      style={{
                        width: "38px",
                        height: "38px",
                        objectFit: "contain",
                        display: "block",
                        maxWidth: "100%",
                        padding: "0",
                        marginRight: "20.29px",
                      }}
                    />

                    <div
                      className="col fw-700 fs-18 lh-243"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        maxWidth: "70%",
                        flexWrap: "nowrap",
                        overflow: "auto",
                        padding: "0px",
                      }}
                    >
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip id={`tooltip-top`}>{p.name}</Tooltip>}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {p.name}
                        </div>
                      </OverlayTrigger>
                    </div>

                    <div className="col">
                      <button
                        type="button"
                        onClick={() => toggleProjectOpenIndex(i)}
                        style={{
                          marginRight: "50.12px",
                          height: "44px",
                          width: "71px",
                          float: "right",
                        }}
                        className="btn-secondary align-items-center fw-700"
                      >
                        <div className="mp-btn-fix">
                          {projectOpen[i] ? "Close" : "Open"}
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className={projectOpen[i] ? "" : "d-none"}>
                    <div className="d-flex" style={{ marginTop: "50px" }}>
                      {tabItems.map((item) => {
                        return (
                          <div key={item.label}>
                            <div className="tab-item" key={item.label}>
                              <div
                                onClick={() =>
                                  handleProjectStateChange(
                                    item.label,
                                    "activeTab",
                                    i,
                                  )
                                }
                                className={
                                  p.activeTab === item.label
                                    ? "fw-700 fc-blue fs-16"
                                    : "fw-500 fc-black fs-16"
                                }
                              >
                                {item.label}
                              </div>
                            </div>
                            <div
                              className="tab-bar-insights"
                              style={{
                                background:
                                  p.activeTab === item.label
                                    ? "#2A00FF"
                                    : "none",
                                width:
                                  p.activeTab === "insights" ? "65%" : "85%",
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>

                    <hr style={{ marginTop: "5px" }} />

                    {p.activeTab === "General" ? (
                      <>
                        <div className="fs-18 fw-700 lh-243 manage-projects-section-header">
                          Name of your project
                        </div>
                        <div
                          style={{ minHeight: "80px", marginTop: "12px" }}
                          className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023 extend-left"
                        >
                          <div style={{ marginTop: "12px" }}>
                            <CustomInput
                              placeholder="Name of your project"
                              containerClassName="observation-text-input-container profile-width-input"
                              inputClassNames="profile-width-input"
                              className={p?.name === "" ? "active" : ""}
                              valueState={p?.name}
                              onChange={(e) => {
                                let value = e.target.value;
                                handleProjectStateChange(value, "name", i);
                                handleProjectStateChange(
                                  true,
                                  "nameChanged",
                                  i,
                                );
                              }}
                              value={p?.name}
                            />
                          </div>
                          {p?.nameChanged && (
                            <button
                              onClick={() => handleNameClick(i)}
                              disabled={savingToDb}
                              style={{
                                marginTop: "20.2px",
                                height: "44px",
                                width: "66px",
                              }}
                              className="btn-primary d-flex align-items-center"
                            >
                              {savingToDb ? (
                                <Spinner
                                  style={{ width: "25px", height: "25px" }}
                                />
                              ) : (
                                <div className="mp-btn-fix">Save</div>
                              )}
                            </button>
                          )}
                        </div>

                        {p?.myRole && p?.myRole !== "client" ? (
                          <></>
                        ) : (
                          <>
                            <div className="fs-18 fw-700 lh-243 manage-projects-section-header">
                              Organization of your project
                            </div>
                            <div
                              style={{ minHeight: "80px", marginTop: "12px" }}
                              className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023 extend-left"
                            >
                              <div
                                className="no-margins-or-padding"
                                style={{ marginBottom: "0px" }}
                              >
                                <div
                                  style={{ marginTop: "12px", width: "504px" }}
                                >
                                  <CustomDropdown
                                    onChange={(option) => {
                                      handleProjectStateChange(
                                        option,
                                        "selectedOrg",
                                        i,
                                      );
                                      handleProjectStateChange(
                                        true,
                                        "orgChanged",
                                        i,
                                      );
                                    }}
                                    value={p?.selectedOrg}
                                    placeholder={"Name of your organization"}
                                    unstyled
                                    options={orgOptions}
                                    menuOpenState={p?.orgMenuIsOpen}
                                    setMenuOpenState={(e) => {
                                      handleProjectStateChange(
                                        e,
                                        "orgMenuIsOpen",
                                        i,
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="row profile-width-input">
                                <div className="col">
                                  {p?.orgChanged && (
                                    <button
                                      onClick={() => handleOrgClick(i)}
                                      disabled={savingToDb}
                                      style={{
                                        marginTop: "20.2px",
                                        height: "44px",
                                        width: "66px",
                                      }}
                                      className="btn-primary d-flex align-items-center"
                                    >
                                      {savingToDb ? (
                                        <Spinner
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                        />
                                      ) : (
                                        <div className="mp-btn-fix">Save</div>
                                      )}
                                    </button>
                                  )}
                                </div>

                                <div className="col text-end">
                                  <button
                                    onClick={() => {
                                      if (userInfo.role !== "client") {
                                        toast.error(
                                          "Invited users cannot create Organizations",
                                        );
                                        return;
                                      }
                                      setSelectedProject(p?.id);
                                      setAddOrgModalIsOpen(true);
                                    }}
                                    disabled={savingToDb}
                                    style={{
                                      marginTop: "20.2px",
                                      height: "44px",
                                      width: "100px",
                                      marginRight: "-20px",
                                    }}
                                    className="btn-secondary align-items-center"
                                  >
                                    {savingToDb ? (
                                      <Spinner
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                      />
                                    ) : (
                                      <div className="mp-btn-fix">Add new</div>
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="fs-18 fw-700 lh-243 manage-projects-section-header">
                          Industry
                        </div>
                        <div
                          style={{ minHeight: "80px", marginTop: "12px" }}
                          className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023 extend-left"
                        >
                          <p style={{ maxWidth: "500px" }}>
                            Choose the industry of your project to compare you
                            to the competition on metric and goals.
                          </p>
                          <div
                            style={{
                              marginTop: "16px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {!p.showIndustryDropdown && (
                              <>
                                <button
                                  type="button"
                                  style={{
                                    height: "44px",
                                    padding: "10px",
                                    border: "2px solid #000000",
                                    minWidth: "100px",
                                  }}
                                  className="btn-secondary align-items-center fw-700"
                                >
                                  <div
                                    className="mp-btn-fix"
                                    style={{
                                      fontSize: "14px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {getIndustryOption(p.industry)?.label ||
                                      getIndustryOption("")?.label}
                                  </div>
                                </button>
                                <button
                                  type="button"
                                  className="btn-invis fc-blue underline fw-700 d-flex align-items-center my-3"
                                  onClick={() =>
                                    handleProjectStateChange(
                                      true,
                                      "showIndustryDropdown",
                                      i,
                                    )
                                  }
                                >
                                  Modify industry
                                </button>
                              </>
                            )}
                          </div>

                          {p.showIndustryDropdown && (
                            <>
                              <div
                                className="no-margins-or-padding"
                                style={{ marginBottom: "0px" }}
                              >
                                <div
                                  style={{ marginTop: "16px", width: "504px" }}
                                >
                                  <CustomDropdown
                                    options={industryOptions}
                                    onChange={(option) => {
                                      handleProjectStateChange(
                                        option,
                                        "selectedIndustry",
                                        i,
                                      );
                                      if (option.value !== p.industry) {
                                        handleProjectStateChange(
                                          true,
                                          "industryChanged",
                                          i,
                                        );
                                      } else {
                                        handleProjectStateChange(
                                          false,
                                          "industryChanged",
                                          i,
                                        );
                                      }
                                    }}
                                    value={p.selectedIndustry}
                                    placeholder="Industry tags"
                                    unstyled
                                    menuOpenState={p.industryMenuIsOpen}
                                    setMenuOpenState={(state) =>
                                      handleProjectStateChange(
                                        state,
                                        "industryMenuIsOpen",
                                        i,
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                style={{ display: "flex", marginTop: "20.2px" }}
                              >
                                <button
                                  onClick={() => handleIndustrySaveClick(i)}
                                  disabled={!p.industryChanged || savingToDb}
                                  style={{
                                    height: "44px",
                                    width: "66px",
                                    marginRight: "10px",
                                  }}
                                  className="btn-primary d-flex align-items-center"
                                >
                                  {savingToDb ? (
                                    <Spinner
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  ) : (
                                    <div className="mp-btn-fix">Save</div>
                                  )}
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="fs-18 fw-700 lh-243 manage-projects-section-header">
                          Link(s)
                        </div>
                        <div
                          style={{ minHeight: "80px", marginTop: "12px" }}
                          className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023 extend-left"
                        >
                          <p style={{ maxWidth: "500px" }}>
                            This is the main domain website of your digital
                            product and all the links that the AI analyses.
                          </p>
                          <div style={{ marginTop: "12px" }}>
                            <CustomInput
                              placeholder="Main domain"
                              containerClassName="observation-text-input-container profile-width-input"
                              inputClassNames="profile-width-input"
                              className={p?.rootUrl === "" ? "active" : ""}
                              valueState={p?.rootUrl}
                              onChange={(e) => {
                                handleProjectStateChange(
                                  e.target.value,
                                  "rootUrl",
                                  i,
                                );
                                handleProjectStateChange(
                                  true,
                                  "urlsChanged",
                                  i,
                                );
                                checkIfUrlsValid(i);
                              }}
                              value={p?.rootUrl}
                            />
                            {!urlIsValid(p?.rootUrl) && (
                              <div className="password-mismatch-text-on">
                                Invalid main domain.
                              </div>
                            )}
                          </div>
                          {p?.otherUrls?.map((o, j) => (
                            <div
                              key={j}
                              style={{
                                marginTop: "12px",
                                position: "relative",
                                maxWidth: "504px",
                                minWidth: "300px",
                              }}
                            >
                              <CustomInput
                                placeholder="Other urls"
                                containerClassName="observation-text-input-container profile-width-input"
                                inputClassNames="profile-width-input"
                                className={o === "" ? "active" : ""}
                                valueState={o}
                                onChange={(e) => {
                                  handleOtherUrlChange(e.target.value, i, j);
                                  checkIfUrlsValid(i);
                                }}
                                value={o}
                              />
                              <button
                                onClick={() => {
                                  handleRemoveOtherUrl(i, j);
                                  checkIfUrlsValid(i);
                                }}
                                type="button"
                                style={{
                                  position: "absolute",
                                  right: "0px",
                                  top: "10px",
                                  backgroundColor: "transparent",
                                }}
                              >
                                <i className="fa-solid fa-xmark fs-20 fc-black"></i>
                              </button>
                            </div>
                          ))}

                          <div className="profile-width-input">
                            <div className="row">
                              {!p.urlsValid &&
                                (!p.otherUrls ||
                                  p.otherUrls[p.otherUrls.length - 1] !==
                                    "") && (
                                  <div className="password-mismatch-text-on">
                                    URLs invalid. All URLs must belong to the
                                    same domain.
                                  </div>
                                )}
                              <div className="col">
                                {p?.urlsChanged && (
                                  <button
                                    onClick={() => handleLinksSaveClick(i)}
                                    disabled={!p.urlsValid || savingToDb}
                                    style={{
                                      marginTop: "20.2px",
                                      height: "44px",
                                      width: "66px",
                                    }}
                                    className="btn-primary align-items-center"
                                  >
                                    {savingToDb ? (
                                      <Spinner
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                      />
                                    ) : (
                                      <div className="mp-btn-fix">Save</div>
                                    )}
                                  </button>
                                )}
                              </div>
                              <div className="col text-end">
                                <button
                                  onClick={() => addOtherUrlsToProject(i)}
                                  disabled={savingToDb}
                                  style={{
                                    marginTop: "20.2px",
                                    height: "44px",
                                    width: "100px",
                                  }}
                                  className="btn-secondary align-items-center"
                                >
                                  {savingToDb ? (
                                    <Spinner
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  ) : (
                                    <div className="mp-btn-fix">Add new</div>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="fs-18 fw-700 lh-243 manage-projects-section-header">
                            Picto of your project
                          </div>
                          <div
                            style={{ minHeight: "80px", marginTop: "12px" }}
                            className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023 extend-left"
                          >
                            <p>Choose an image more or less foursquare.</p>

                            <>
                              <button
                                type="button"
                                className="btn-invis fc-blue underline fw-700 d-flex align-items-center my-3"
                                onClick={() =>
                                  document
                                    .getElementById(`project-image-upload ${i}`)
                                    .click()
                                }
                              >
                                <img
                                  src={p?.image}
                                  alt="Project"
                                  style={{ width: "38px", height: "38px" }}
                                  className="me-3"
                                />
                                Change image
                              </button>
                              <input
                                type="file"
                                id={`project-image-upload ${i}`}
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  onSelectImage(e, i);
                                }}
                                accept=".jpeg, .jpg"
                              />
                            </>
                          </div>
                          {p?.imageChanged && (
                            <button
                              onClick={() => {
                                handleImageClick(i);
                                handleProjectStateChange(
                                  false,
                                  "imageChanged",
                                  i,
                                );
                              }}
                              disabled={savingToDb}
                              style={{
                                marginTop: "20.2px",
                                height: "44px",
                                width: "66px",
                              }}
                              className="btn-primary d-flex align-items-center"
                            >
                              {savingToDb ? (
                                <Spinner
                                  style={{ width: "25px", height: "25px" }}
                                />
                              ) : (
                                <div className="mp-btn-fix">Save</div>
                              )}
                            </button>
                          )}
                        </div>
                        <div className="fs-18 fw-700 lh-243 manage-projects-section-header">
                          Delete project
                        </div>
                        <div
                          style={{ minHeight: "80px", marginTop: "12px" }}
                          className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023 extend-left"
                        >
                          <p>You can delete your project here.</p>
                          <p>
                            You will still be able to consult your project
                            reports but you will not
                          </p>
                          <p>receive anymore UX/UI recommandations.</p>
                          <p>
                            You won’t see this project in your dashboard
                            anymore.
                          </p>
                          <div style={{ marginTop: "23.52px" }}>
                            <button
                              type="button"
                              onClick={() => {
                                setProjectToDelete(p);
                                setDeleteProjectConfirmModalIsOpen(true);
                              }}
                              style={{
                                marginRight: "50.12px",
                                height: "44px",
                                width: "156px",
                              }}
                              className="btn-secondary align-items-center fw-700"
                            >
                              <div
                                className="mp-btn-fix"
                                style={{ fontSize: "13.34px" }}
                              >
                                Delete this project
                              </div>
                            </button>
                          </div>
                        </div>
                      </>
                    ) : p.activeTab === "Analytics & Limits" ? (
                      <>
                        <div className="fs-18 fw-700 lh-243 manage-projects-section-header">
                          Analytic properties
                        </div>

                        <div
                          style={{ minHeight: "80px", marginTop: "12px" }}
                          className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023 extend-left"
                        >
                          <p>You can manage analytic properties here</p>
                          <div style={{ marginTop: "12px" }}>
                            {p?.platform === "GA4" ? (
                              <>
                                <CustomInput
                                  disabled={!p?.analyticsChanged || savingToDb}
                                  placeholder="Google Analytics"
                                  containerClassName={`${
                                    !p?.analyticsChanged || savingToDb
                                      ? "disabled"
                                      : ""
                                  } observation-text-input-container profile-width-input`}
                                  inputClassNames="profile-width-input"
                                  className={
                                    p?.googleAnalyticsId === "" ? "active" : ""
                                  }
                                  valueState={p?.googleAnalyticsId}
                                  onChange={(e) => {
                                    handleProjectStateChange(
                                      e.target.value,
                                      "googleAnalyticsId",
                                      i,
                                    );
                                  }}
                                  value={p?.googleAnalyticsId}
                                />
                                <div style={{ position: "relative" }}>
                                  {p?.validGA4 === 1 &&
                                    p?.platform === "GA4" &&
                                    p?.googleAnalyticsId && (
                                      <img
                                        alt="✓"
                                        src={check}
                                        className="check check-full-width-offset"
                                      />
                                    )}
                                </div>
                              </>
                            ) : p?.platform === "matomo" ? (
                              <>
                                <CustomInput
                                  disabled={!p?.analyticsChanged || savingToDb}
                                  placeholder="Site ID"
                                  containerClassName={`${
                                    !p?.analyticsChanged || savingToDb
                                      ? "disabled"
                                      : ""
                                  } observation-text-input-container profile-width-input`}
                                  inputClassNames="profile-width-input"
                                  className={p?.matomoId === "" ? "active" : ""}
                                  valueState={p?.matomoId}
                                  onChange={(e) => {
                                    handleProjectStateChange(
                                      e.target.value,
                                      "matomoId",
                                      i,
                                    );
                                  }}
                                  value={p?.matomoId}
                                />
                                <div style={{ position: "relative" }}>
                                  {p?.validMatomo === 1 &&
                                    p?.platform === "matomo" &&
                                    p?.matomoId && (
                                      <img
                                        alt="✓"
                                        src={check}
                                        className="check check-full-width-offset"
                                      />
                                    )}
                                </div>
                                <CustomInput
                                  disabled={!p?.analyticsChanged || savingToDb}
                                  placeholder="API Key"
                                  containerClassName={`${
                                    !p?.analyticsChanged || savingToDb
                                      ? "disabled"
                                      : ""
                                  } observation-text-input-container profile-width-input`}
                                  inputClassNames="profile-width-input"
                                  className={
                                    p?.matomoApiToken === "" ? "active" : ""
                                  }
                                  valueState={p?.matomoApiToken}
                                  onChange={(e) => {
                                    handleProjectStateChange(
                                      e.target.value,
                                      "matomoApiToken",
                                      i,
                                    );
                                  }}
                                  value={p?.matomoApiToken}
                                />
                                <div style={{ position: "relative" }}>
                                  {p?.validMatomo === 1 &&
                                    p?.platform === "matomo" &&
                                    p?.matomoApiToken && (
                                      <img
                                        alt="✓"
                                        src={check}
                                        className="check check-full-width-offset"
                                      />
                                    )}
                                </div>
                                <CustomInput
                                  disabled={!p?.analyticsChanged || savingToDb}
                                  placeholder="Matomo URL"
                                  containerClassName={`${
                                    !p?.analyticsChanged || savingToDb
                                      ? "disabled"
                                      : ""
                                  } observation-text-input-container profile-width-input`}
                                  inputClassNames="profile-width-input"
                                  className={
                                    p?.matomoUrl === "" ? "active" : ""
                                  }
                                  valueState={p?.matomoUrl}
                                  onChange={(e) => {
                                    handleProjectStateChange(
                                      e.target.value,
                                      "matomoUrl",
                                      i,
                                    );
                                  }}
                                  value={p?.matomoUrl}
                                />
                                <div style={{ position: "relative" }}>
                                  {p?.validMatomo === 1 &&
                                    p?.platform === "matomo" &&
                                    p?.matomoUrl && (
                                      <img
                                        alt="✓"
                                        src={check}
                                        className="check check-full-width-offset"
                                      />
                                    )}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            <div style={{ position: "relative" }}>
                              {p?.validGA4 === -1 && p?.platform === "GA4" && (
                                <div className="password-mismatch-text-on">
                                  Invalid Google Analytics ID.
                                </div>
                              )}

                              {p?.validMatomo === -1 &&
                                p?.platform === "matomo" && (
                                  <div className="password-mismatch-text-on">
                                    Invalid Matomo data.
                                  </div>
                                )}
                            </div>
                          </div>

                          <button
                            onClick={() => {
                              if (p?.analyticsChanged) {
                                if (p?.platform === "GA4") {
                                  handleAnalyticsSaveClickGA4(i);
                                } else if (p?.platform === "matomo") {
                                  handleAnalyticsSaveClickMatomo(i);
                                }
                              } else {
                                handleProjectStateChange(
                                  true,
                                  "analyticsChanged",
                                  i,
                                );
                              }
                            }}
                            disabled={savingToDb}
                            style={{
                              marginTop: "20.2px",
                              height: "44px",
                              textAlign: "center",
                            }}
                            className="btn-primary d-flex align-items-center"
                          >
                            {savingToDb ? (
                              <Spinner
                                style={{ width: "25px", height: "25px" }}
                              />
                            ) : (
                              <div className="mp-btn-fix">
                                {p?.analyticsChanged ? "Save" : "Modify"}
                              </div>
                            )}
                          </button>
                        </div>
                      </>
                    ) : p.activeTab === "Commercial value" ? (
                      <>
                        <div className="fs-18 fw-700 lh-243 manage-projects-section-header">
                          Average purchase value per customer
                        </div>
                        <div
                          style={{ minHeight: "80px", marginTop: "12px" }}
                          className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023 extend-left"
                        >
                          <p>
                            This is the average value for a customer. We will
                            use this data to
                          </p>
                          <p>
                            predict the potential gain to be made by fixing the
                            problems we
                          </p>
                          <p>observe linked to a potential purchase.</p>
                          <div style={{ marginTop: "12px" }}>
                            <CustomInput
                              placeholder="Average purchase value per customer"
                              containerClassName="observation-text-input-container profile-width-input"
                              inputClassNames="profile-width-input"
                              className={
                                p?.purchaseValue === "" ? "active" : ""
                              }
                              valueState={p?.purchaseValue}
                              onChange={(e) => {
                                let value = e.target.value;
                                if (/^\d{0,8}(\.\d{0,4})?$/.test(value)) {
                                  if (value.startsWith(".")) {
                                    value = "0" + value;
                                  }
                                  handleProjectStateChange(
                                    value,
                                    "purchaseValue",
                                    i,
                                  );
                                  handleProjectStateChange(
                                    true,
                                    "purchaseValueChanged",
                                    i,
                                  );
                                }
                              }}
                              value={p?.purchaseValue}
                            />
                          </div>
                          <div
                            className="no-margins-or-padding"
                            style={{ marginBottom: "0px" }}
                          >
                            <div style={{ marginTop: "12px", width: "504px" }}>
                              <CustomDropdown
                                onChange={(option) => {
                                  handleProjectStateChange(
                                    option,
                                    "selectedCurrency",
                                    i,
                                  );
                                  handleProjectStateChange(
                                    true,
                                    "purchaseValueChanged",
                                    i,
                                  );
                                }}
                                value={p?.selectedCurrency}
                                placeholder={"Currency"}
                                unstyled
                                options={currencyOptions}
                                menuOpenState={p?.currencyMenuIsOpen}
                                setMenuOpenState={(e) => {
                                  handleProjectStateChange(
                                    e,
                                    "currencyMenuIsOpen",
                                    i,
                                  );
                                }}
                              />
                            </div>
                          </div>

                          {p?.purchaseValueChanged && (
                            <button
                              onClick={() => handlePurchaseValueSaveClick(i)}
                              disabled={
                                savingToDb ||
                                !(p?.selectedCurrency && p?.purchaseValue)
                              }
                              style={{
                                marginTop: "20.2px",
                                height: "44px",
                                width: "66px",
                              }}
                              className="btn-primary d-flex align-items-center"
                            >
                              {savingToDb ? (
                                <Spinner
                                  style={{ width: "25px", height: "25px" }}
                                />
                              ) : (
                                <div className="mp-btn-fix">Save</div>
                              )}
                            </button>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="fs-18 fw-700 lh-243 manage-projects-section-header">
                          Sensitivity of your metric alerts
                        </div>
                        <div
                          style={{ minHeight: "80px", marginTop: "12px" }}
                          className="fs-16 fw-500 lh-20 profile-text-container fc-grey lc-023 extend-left"
                        >
                          <p>
                            Manage the sensitivity of your daily metric anomaly
                          </p>
                          <p>notifications and insights.</p>
                          <div
                            className="no-margins-or-padding"
                            style={{ marginBottom: "0px" }}
                          >
                            <div style={{ marginTop: "18px", width: "504px" }}>
                              <CustomDropdown
                                onChange={(option) => {
                                  handleProjectStateChange(
                                    option,
                                    "selectedSensitivity",
                                    i,
                                  );
                                  handleProjectStateChange(
                                    true,
                                    "sensitivityValueChanged",
                                    i,
                                  );
                                }}
                                value={p?.selectedSensitivity}
                                placeholder={"Metric Sensitivity"}
                                unstyled
                                options={sensitivityOptions}
                                menuOpenState={p?.sensitivityMenuIsOpen}
                                setMenuOpenState={(e) => {
                                  handleProjectStateChange(
                                    e,
                                    "sensitivityMenuIsOpen",
                                    i,
                                  );
                                }}
                              />
                            </div>
                          </div>

                          {p?.sensitivityValueChanged && (
                            <button
                              onClick={() => handleSensitivityValueSaveClick(i)}
                              disabled={savingToDb || !p?.selectedSensitivity}
                              style={{
                                marginTop: "20.2px",
                                height: "44px",
                                width: "66px",
                              }}
                              className="btn-primary d-flex align-items-center"
                            >
                              {savingToDb ? (
                                <Spinner
                                  style={{ width: "25px", height: "25px" }}
                                />
                              ) : (
                                <div className="mp-btn-fix">Save</div>
                              )}
                            </button>
                          )}
                        </div>
                      </>
                    )}

                    {/* Single project section ends here */}
                  </div>
                  <hr
                    className="row"
                    style={{
                      width: "95%",
                      marginTop: "32px",
                      marginBottom: "0px",
                    }}
                  ></hr>
                </div>
              ))}
              {projectStates && projectStates.length === 0 && (
                <h2>You have no projects to manage</h2>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default function ManageProjects() {
  const [userInfo, setUserInfo] = useState(null);
  const [hasUserInfo, setHasUserInfo] = useState(false);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const [savingToDb, setSavingToDb] = useState(false);

  const [deleteProjectConfirmModalIsOpen, setDeleteProjectConfirmModalIsOpen] =
    useState(false);

  const [confirmDelete, setConfirmDelete] = useState("");
  const [deleteValid, setDeleteValid] = useState(false);
  const [isGoogleAccount, setIsGoogleAccount] = useState(false);
  const [projects, setProjects] = useState(null);
  const [orgOptions, setOrgOptions] = useState(null);

  const [projectToDelete, setProjectToDelete] = useState(null);

  const [addOrgModalIsOpen, setAddOrgModalIsOpen] = useState(false);
  const [newOrgName, setNewOrgName] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteConfirmTextChange = (value) => {
    setConfirmDelete(value);
    setDeleteValid(value === "DELETE");
  };

  useEffect(() => {
    // Whenever the modal is opened or closed, reset the confirmation text.
    setConfirmDelete("");
  }, [deleteProjectConfirmModalIsOpen]);

  async function loadPageContents() {
    setHasUserInfo(true);
    const info = await getUserInformation();

    setUserInfo(info);
    setIsGoogleAccount(info?.isGoogleAccount);

    const organizations = await getClientOrganizations(auth?.currentUser?.uid);
    setOrgOptions(
      organizations.map((org) => ({ label: org.name, value: org.id })),
    );
    getUserProjects(info).then((projects) => {
      const updatedProjects = projects.map((project) => {
        const org = organizations.find((org) =>
          org.projectIds.includes(project.id),
        );
        return {
          ...project,
          projectTags: project.projectTags || [],
          purchaseValue: project.purchaseValue || "",
          selectedOrg: org ? { label: org.name, value: org.id } : null,
        };
      });

      const projectsWithEditPermissions = updatedProjects.filter(
        (project) => !project.myRole || project.myRole !== "reader",
      );

      setProjects(projectsWithEditPermissions);
    });
  }

  useEffect(() => {
    const fetchUserInformation = async () => {
      if (user) {
        loadPageContents();
      }
    };
    if (!hasUserInfo) fetchUserInformation();
  }, [user]);

  useEffect(() => {
    if (
      !loading &&
      (!user ||
        (user && userInfo && ["designer", "reader"].includes(userInfo.role)))
    ) {
      navigate("/");
    }
  }, [loading, userInfo, user]);

  /**
   * If the page is loading, or user info is loading, the Spinning wheel will be shown.
   */
  if (loading || (user && !userInfo?.role)) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner style={{ height: "70px", width: "70px" }} />
      </div>
    );
  }

  if (user) {
    return (
      <>
        <Modal
          isOpen={deleteProjectConfirmModalIsOpen}
          contentLabel="Delete project modal"
          className="delete-confirm-modal custom-modal modal-shape2"
          appElement={document.getElementById("root")}
          style={{
            overlay: {
              background: "rgb(42, 0, 255, 0.7)",
              backdropFilter: "blur(8px)",
              zIndex: 10,
            },
          }}
        >
          <div>
            <button
              className="d-flex justify-content-center align-items-center delete-confirm-x-btn"
              onClick={() => setDeleteProjectConfirmModalIsOpen(false)}
            >
              <BsXCircle alt="X button" size="32px" color="#0F0C22" />
            </button>
          </div>
          <div className="text-center mt-3">
            <h3 className="mb-4 mp-ellipsis-text">
              You are deleting "{projectToDelete?.name}".
            </h3>
            <h5 className="mb-4">
              Enter “DELETE” to permanently delete your project.
            </h5>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <CustomInput
              placeholder="DELETE"
              containerClassName="observation-text-input-container delete-width-input"
              inputClassNames="delete-width-input"
              className={confirmDelete === "" ? "active" : ""}
              valueState={confirmDelete}
              onChange={(e) => handleDeleteConfirmTextChange(e.target.value)}
              value={confirmDelete}
            />
          </div>
          <div className="password-mismatch-text-on d-flex justify-content-center align-items-center">
            {!deleteValid &&
              confirmDelete !== "" &&
              'You did not enter "DELETE" correctly.'}
          </div>
          <div className="row justify-content-center d-flex align-items-center">
            <button
              className="delete-confirm-btn btn-primary mb-5"
              onClick={async () => {
                setIsLoading(true);
                handleDeleteProject(projectToDelete?.value).then(() => {
                  window.location.reload();
                });
              }}
              disabled={!deleteValid || isLoading}
            >
              <span style={{ color: "white" }}>Confirm</span>
            </button>
          </div>
        </Modal>
        <AddOrganizationModal
          isOpen={addOrgModalIsOpen}
          newOrgName={newOrgName}
          setNewOrgName={setNewOrgName}
          setAddOrgModalIsOpen={setAddOrgModalIsOpen}
          setOrgOptions={setOrgOptions}
          setSelectedOrg={(option) => {
            setProjects((prevProjects) => {
              return prevProjects.map((project) => {
                if (project.id === selectedProject) {
                  return {
                    ...project,
                    selectedOrg: option,
                    orgChanged: true,
                  };
                }
                return project;
              });
            });
          }}
        />
        <ManageProjectsContent
          userInfo={userInfo}
          user={user}
          setUserInfo={setUserInfo}
          setDeleteProjectConfirmModalIsOpen={
            setDeleteProjectConfirmModalIsOpen
          }
          setSavingToDb={setSavingToDb}
          savingToDb={savingToDb}
          isGoogleAccount={isGoogleAccount}
          projects={projects}
          setProjects={setProjects}
          setProjectToDelete={setProjectToDelete}
          orgOptions={orgOptions}
          setAddOrgModalIsOpen={setAddOrgModalIsOpen}
          setSelectedProject={setSelectedProject}
        >
          <ToastContainer />
        </ManageProjectsContent>
      </>
    );
  }
}
