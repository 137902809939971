/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useMemo, useState, useContext } from "react";
import { XYChartTheme } from "@visx/xychart";
import { GlyphProps } from "@visx/xychart/lib/types";
import { GlyphDot, Glyph as CustomGlyph } from "@visx/glyph";
import { curveLinear, curveStep, curveCardinal } from "@visx/curve";
import customTheme from "./customTheme.ts";
import getAnimatedOrUnanimatedComponents from "./getAnimatedOrUnanimatedComponents.ts";
import { Annotation, EditableAnnotation } from "@visx/annotation";
import luxi from "../../../assets/LUXI.png";
import popper from "../../../assets/popper.png";
import { ChartInsightsContext, ChartRangeContext } from "../../../contexts.js";
import { MetricData } from "./metricData";
import { Timestamp } from "firebase/firestore";

const dateScaleConfig = { type: "band", paddingInner: 0.3 } as const;
const temperatureScaleConfig = { type: "linear" } as const;
const data = [];
const getDate = (d: MetricData) => {
  return d?.date ?? null;
};
const getSfTemperature = (d: MetricData) => Number(d["data"]);
const getNegativeSfTemperature = (d: MetricData) => -getSfTemperature(d);
const defaultAnnotationDataIndex = 13;
const selectedDatumPatternId = "xychart-selected-datum";

type Accessor = (d: MetricData) => number | string;

interface Accessors {
  data: Accessor;
}

type DataKey = keyof Accessors;

type SimpleScaleConfig = { type: "point" };

type ProvidedProps = {
  AnnotationComponent: typeof Annotation | typeof EditableAnnotation;
  numTicksX;
  numTicksY;
  accessors: {
    x: Accessors;
    y: Accessors;
    date: Accessor;
  };
  annotationDataKey: DataKey | null;
  annotationDatum?: MetricData;
  annotationLabelPosition: { dx: number; dy: number };
  annotationType?: "line" | "circle";
  colorAccessorFactory: (key: DataKey) => (d: MetricData) => string | null;
  config: {
    x: SimpleScaleConfig;
    y: SimpleScaleConfig;
  };
  curve: typeof curveLinear | typeof curveCardinal | typeof curveStep;
  data: MetricData[];
  editAnnotationLabelPosition: boolean;
  numTicks: number;
  setAnnotationDataIndex: (index: number) => void;
  setAnnotationDataKey: (key: DataKey | null) => void;
  setAnnotationLabelPosition: (position: { dx: number; dy: number }) => void;
  renderAreaSeries: boolean;
  renderAreaStack: boolean;
  renderBarGroup: boolean;
  renderBarSeries: boolean;
  renderBarStack: boolean;
  renderGlyph: React.FC<GlyphProps<MetricData>>;
  renderGlyphSeries: boolean;
  enableTooltipGlyph: boolean;
  renderHorizontally: boolean;
  renderLineSeries: boolean;
  sharedTooltip: boolean;
  showGridColumns: boolean;
  showGridRows: boolean;
  showHorizontalCrosshair: boolean;
  showTooltip: boolean;
  showVerticalCrosshair: boolean;
  snapTooltipToDatumX: boolean;
  snapTooltipToDatumY: boolean;
  stackOffset?: "wiggle" | "expand" | "diverging" | "silhouette";
  theme: XYChartTheme;
  range: number;
};

type ControlsProps = {
  children: (props: ProvidedProps) => React.ReactNode;
};

export default function MetricsChartControls({ children }: ControlsProps) {
  const [useAnimatedComponents, setUseAnimatedComponents] = useState(false);
  const [theme, setTheme] = useState<XYChartTheme>(customTheme);
  const [gridProps, setGridProps] = useState<[boolean, boolean]>([true, true]);
  const [showGridRows, showGridColumns] = gridProps;
  const [renderHorizontally, setRenderHorizontally] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [annotationDataKey, setAnnotationDataKey] =
    useState<ProvidedProps["annotationDataKey"]>(null);
  const [annotationType, setAnnotationType] =
    useState<ProvidedProps["annotationType"]>("circle");
  const [showVerticalCrosshair, setShowVerticalCrosshair] = useState(false);
  const [showHorizontalCrosshair, setShowHorizontalCrosshair] = useState(false);
  const [snapTooltipToDatumX, setSnapTooltipToDatumX] = useState(true);
  const [snapTooltipToDatumY, setSnapTooltipToDatumY] = useState(true);
  const [sharedTooltip, setSharedTooltip] = useState(true);
  const [renderBarStackOrGroup, setRenderBarStackOrGroup] = useState("none");
  const [renderAreaLineOrStack, setRenderAreaLineOrStack] = useState("area");
  const [stackOffset, setStackOffset] =
    useState<ProvidedProps["stackOffset"]>();
  const [renderGlyphSeries, setRenderGlyphSeries] = useState(true);
  const [editAnnotationLabelPosition, setEditAnnotationLabelPosition] =
    useState(false);
  const [annotationLabelPosition, setAnnotationLabelPosition] = useState({
    dx: -40,
    dy: -20,
  });
  const [annotationDataIndex, setAnnotationDataIndex] = useState(
    defaultAnnotationDataIndex,
  );
  const [negativeValues, setNegativeValues] = useState(false);
  const [glyphComponent, setGlyphComponent] = useState("circle");
  const [curveType, setCurveType] = useState("cardinal");
  const glyphOutline = theme.gridStyles.stroke;

  function getDateWithoutTime(date: Date) {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  const chartInsights = useContext(ChartInsightsContext);
  const range = useContext(ChartRangeContext);
  const sameTime = (d1, d2) => {
    return (
      getDateWithoutTime(d1).getTime() === getDateWithoutTime(d2).getTime()
    );
  };

  interface SelectedInsight {
    status: string | null;
    date: Timestamp | null;
    fixEase: string | null;
    num: number | null;
    originalDate: Timestamp | null;
    reportId: string | null;
    reportnumber: number | null;
    severity: string | null;
    title: string | null;
  }

  const renderGlyph = useCallback(
    ({
      datum,
      x,
      y,
      onPointerMove,
      onPointerOut,
      onPointerUp,
    }: GlyphProps<MetricData>) => {
      const radius = range === 96 ? 2 : 5;
      const strokeWidthValue = range === 96 ? 1 : 2;

      const handlers = { onPointerMove, onPointerOut, onPointerUp };
      if (glyphComponent === "circle") {
        if (!chartInsights || chartInsights.length === 0) {
          return (
            <GlyphDot
              left={x}
              top={y}
              stroke="#2A00FF"
              fill="white"
              r={radius}
              strokeWidth={strokeWidthValue}
              {...handlers}
            />
          );
        }

        if (chartInsights && datum) {
          return chartInsights.map(
            (insight: SelectedInsight, index: number) => {
              if (sameTime(new Date(datum.date), insight.date)) {
                if (insight.status === "complete") {
                  return (
                    <CustomGlyph left={x} top={y} key={index}>
                      <circle r={20} fill="#2A00FF" />
                      <image
                        href={popper}
                        x={-10}
                        y={-10}
                        width={20}
                        height={20}
                      />
                    </CustomGlyph>
                  );
                } else {
                  return (
                    <CustomGlyph left={x} top={y} key={index}>
                      <image
                        href={luxi}
                        x={-20}
                        y={-20}
                        width={40}
                        height={40}
                      />
                    </CustomGlyph>
                  );
                }
              } else {
                if (
                  chartInsights.every(
                    (insight: SelectedInsight) =>
                      !sameTime(new Date(datum.date), insight.date),
                  )
                ) {
                  return (
                    <GlyphDot
                      left={x}
                      top={y}
                      stroke="#2A00FF"
                      fill="white"
                      r={radius}
                      strokeWidth={strokeWidthValue}
                      key={index}
                      {...handlers}
                    />
                  );
                }
              }
            },
          );
        }
      }
    },
    [glyphComponent, glyphOutline, range],
  );
  const [enableTooltipGlyph, setEnableTooltipGlyph] = useState(false);
  // for series that support it, return a colorAccessor which returns a custom color if the datum is selected
  const colorAccessorFactory = useCallback(
    (dataKey: DataKey) => (d: MetricData) =>
      annotationDataKey === dataKey && d === data[annotationDataIndex]
        ? `url(#${selectedDatumPatternId})`
        : null,
    [annotationDataIndex, annotationDataKey],
  );

  const accessors = useMemo(
    () => ({
      x: {
        data: renderHorizontally
          ? negativeValues
            ? getNegativeSfTemperature
            : getSfTemperature
          : getDate,
      },
      y: {
        data: renderHorizontally
          ? getDate
          : negativeValues
            ? getNegativeSfTemperature
            : getSfTemperature,
      },
      date: getDate,
    }),
    [renderHorizontally, negativeValues],
  );

  const config = useMemo(
    () => ({
      x: renderHorizontally ? temperatureScaleConfig : dateScaleConfig,
      y: renderHorizontally ? dateScaleConfig : temperatureScaleConfig,
    }),
    [renderHorizontally],
  );

  // cannot snap to a stack position
  const canSnapTooltipToDatum =
    renderBarStackOrGroup !== "barstack" &&
    renderAreaLineOrStack !== "areastack";

  return (
    <>
      {children({
        AnnotationComponent: Annotation,
        accessors,
        annotationDataKey,
        //annotationDatum: data[annotationDataIndex],
        annotationLabelPosition,
        annotationType,
        colorAccessorFactory,
        config,
        curve:
          (curveType === "cardinal" && curveCardinal) ||
          (curveType === "step" && curveStep) ||
          curveLinear,
        //  data: data,
        editAnnotationLabelPosition,
        renderBarGroup: renderBarStackOrGroup === "bargroup",
        renderBarSeries: renderBarStackOrGroup === "bar",
        renderBarStack: renderBarStackOrGroup === "barstack",
        renderGlyphSeries,
        renderGlyph,
        enableTooltipGlyph,
        renderHorizontally,
        renderAreaSeries: renderAreaLineOrStack === "area",
        renderAreaStack: renderAreaLineOrStack === "areastack",
        renderLineSeries: renderAreaLineOrStack === "line",
        setAnnotationDataIndex,
        annotationDataIndex,
        setAnnotationDataKey,
        setAnnotationLabelPosition,
        sharedTooltip,
        showGridColumns,
        showGridRows,
        showHorizontalCrosshair,
        showTooltip,
        showVerticalCrosshair,
        snapTooltipToDatumX: canSnapTooltipToDatum && snapTooltipToDatumX,
        snapTooltipToDatumY: canSnapTooltipToDatum && snapTooltipToDatumY,
        stackOffset,
        theme,
        ...getAnimatedOrUnanimatedComponents(useAnimatedComponents),
      })}
      <div className="controls"></div>
      <style>{`
        .controls {
          font-size: 13px;
          line-height: 1.5em;
          height: 7em;
        }
        .controls > div {
          margin-bottom: 4px;
        }
        label {
          font-size: 12px;
        }
        input[type="radio"] {
          height: 10px;
        }

      `}</style>
    </>
  );
}
