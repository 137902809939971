import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";

const RecordingFrame = ({ data }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handlePostMessage = (event) => {
      if (
        event.origin !== "https://analytics.luxifer.app" ||
        !event.data?.action
      ) {
        return;
      }

      if (event.data.action === "fullscreen") {
        toggleFullscreen();
      }
    };

    window.addEventListener("message", handlePostMessage);

    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, []);

  function toggleFullscreen() {
    if (!document?.fullscreenElement) {
      makeFullscreen();
    } else {
      exitFullscreen();
    }
  }

  function makeFullscreen() {
    const iframe = document.getElementById("matomo-frame");
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) {
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      iframe.msRequestFullscreen();
    } else {
      console.error("Fullscreen API is not supported in this browser.");
    }
  }

  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else {
      console.error("Fullscreen API is not supported in this browser.");
    }
  }

  return (
    <div id="widgetIframe">
      {!isLoaded && (
        <div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "100px",
              position: "absolute",
            }}
          >
            <Spinner style={{ height: "70px", width: "70px" }} />
          </div>
        </div>
      )}
      <iframe
        src={`https://analytics.luxifer.app/index.php?module=Widgetize&action=iframe&moduleToWidgetize=HeatmapSessionRecording&actionToWidgetize=replayRecording${data}&token_auth=c512ee37096158190b15952ee65118b7`}
        width="100%"
        height="520px"
        frameBorder="0"
        allowFullScreen
        title="Matomo Session Recording"
        id="matomo-frame"
        name="matomo-frame"
        onLoad={() => setIsLoaded(true)}
        style={{ visibility: isLoaded ? "visible" : "hidden" }}
      ></iframe>
    </div>
  );
};

export default RecordingFrame;
