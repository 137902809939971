import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneLight } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { BsXCircle } from "react-icons/bs";
import CustomInput from "../CustomInput/CustomInput";
import "./ShareAnalyticsScript.css";
import { sendCodeEmail, getUserInformation } from "../../helpers";

function ShareAnalyticsScriptComponent({
  showButton = true,
  type,
  siteid,
  oneTrustCode,
  sampleSize,
}) {
  const [generatedScript, setGeneratedScript] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [firstname, setFirstname] = useState("User");

  const handleEmailChange = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmail(email);
    setValidEmail(emailPattern.test(email));
  };

  useEffect(() => {
    const fetchUserInformation = async () => {
      const user = await getUserInformation();
      setFirstname(user?.firstName || "User");
    };

    fetchUserInformation();
  }, []);

  useEffect(() => {
    const generateScriptContent = () => {
      if (type.toLowerCase() === "onetrust") {
        if (!oneTrustCode || !siteid) {
          return "Error: Missing stats code or site ID for 'onetrust' type.";
        }
        return `
<script>
  // THIS IS A PLACEHOLDER SCRIPT AND IT WILL NOT WORK!
  var matomoLuxiSiteId = "${siteid}";
  var matomoLuxiSampleSize = "10";
  var matomoLuxiStatsCode = "${oneTrustCode}";
  var _mtm = window._mtm = window._mtm || [];
  var _paq = window._paq = window._paq || [];
  (function() {
    var script = document.createElement('script');
    script.src = "https://cdn.jsdelivr.net/gh/Lorem ipsum dolor sit amet, consectetur adipiscing elit.";
    script.integrity = "Fusce augue enim, venenatis ut lectus in, luctus convallis mi.";
    script.crossOrigin = "anonymous";
    script.async = true;
    document.head.appendChild(script);
  })();
</script>`;
      } else if (type === "none") {
        if (!siteid) {
          return "Error: Missing site ID for 'none' type.";
        }
        return `
<script>
  var matomoLuxiSiteId = "${siteid}";
  var matomoLuxiSampleSize = "${sampleSize ?? 10}";
  var _mtm = window._mtm = window._mtm || [];
  var _paq = window._paq = window._paq || [];
  (function() {
    var script = document.createElement('script');
    script.src = "https://cdn.jsdelivr.net/gh/chiukurt/LuxiferData@1.2.02/default.min.js";
    script.integrity = "sha384-aIRAMkKxsFX6tOA6PFhqe85yPRXNadvhxK+X5tGYVLHHrwXdvTU9ma0mio9T+3jZ";
    script.crossOrigin = "anonymous";
    script.async = true;
    document.head.appendChild(script);
  })();
</script>`;
      } else {
        return `Invalid parameters. Please only use the URL you were provided.`;
      }
    };

    setGeneratedScript(generateScriptContent());
  }, [type, siteid, oneTrustCode, sampleSize]);

  const handleCopyCode = () => {
    navigator.clipboard
      .writeText(generatedScript)
      .then(() => {
        toast.success("Code copied to clipboard!", {
          position: "top-right",
          autoClose: 500,
        });
      })
      .catch((err) => {
        return;
      });
  };

  return (
    <>
      {showButton && (
        <div className="fw-500 fs-16 lh-243 fc-grey mb-4">
          Great job, you can copy the code or share with your developers.
        </div>
      )}
      <div
        className="bg-grey fc-black send-code-card-common"
        style={{
          width: "600px",
          padding: "16px",
          borderRadius: "8px",
          border: "1px solid #d7d7d7",
        }}
      >
        <div>
          <div className="fs-16 fw-500 lh-20">
            Put the script right before the closing &lt;/head&gt; tag
          </div>
        </div>
      </div>

      <div
        className="bg-grey fc-black send-code-card-common"
        style={{
          width: "600px",
          marginTop: "16px",
          padding: "12px",
          borderRadius: "8px",
          border: "1px solid #d7d7d7",
        }}
      >
        <div className="fs-16 fw-700 lh-20">
          <SyntaxHighlighter language="javascript" style={atomOneLight}>
            {generatedScript}
          </SyntaxHighlighter>
        </div>
      </div>

      <button
        type="button"
        onClick={handleCopyCode}
        className="btn-quaternary fw-700 mt-16"
        style={{ marginRight: "10px" }}
      >
        Copy code &lt;/&gt;
      </button>

      {showButton && (
        <button
          type="button"
          onClick={() => setShowModal(true)}
          className="btn-quaternary fw-700"
        >
          Send to developer
        </button>
      )}
      <Modal
        isOpen={showModal}
        contentLabel="Send Code via Email"
        className="send-email-modal custom-modal modal-shape2"
        appElement={document.getElementById("root")}
        style={{
          overlay: {
            background: "rgb(42, 0, 255, 0.7)",
            backdropFilter: "blur(8px)",
            zIndex: 10,
          },
        }}
      >
        <div>
          <button
            className="d-flex justify-content-center align-items-center delete-confirm-x-btn"
            onClick={() => setShowModal(false)}
          >
            <BsXCircle alt="X button" size="32px" color="#0F0C22" />
          </button>
        </div>
        <div className="text-center mt-3">
          <h3 className="mb-4">Send Code via Email</h3>
          <h5 className="mb-4">Enter email address to send the code.</h5>
        </div>

        <div className="d-flex justify-content-center align-items-center">
          <CustomInput
            placeholder="Send email"
            containerClassName="observation-text-input-container delete-width-input"
            inputClassNames="delete-width-input"
            className={email === "" ? "active" : ""}
            valueState={email}
            onChange={(e) => handleEmailChange(e.target.value)}
            value={email}
          />
        </div>
        <div
          style={{ paddingBottom: "30px" }}
          className="row justify-content-center mb-5 d-flex align-items-center"
        >
          <button
            className="send-email-confirm-btn btn-primary"
            onClick={async () => {
              try {
                const url =
                  type === "none"
                    ? `https://luxifer.app/send-code?type=none&siteid=${siteid}`
                    : `https://luxifer.app/send-code?type=onetrust&code=${oneTrustCode}&siteid=${siteid}`;

                await sendCodeEmail(email, [
                  {
                    name: "url",
                    content: url,
                  },
                  {
                    name: "name",
                    content: `${firstname}`,
                  },
                ]);
                toast.success("Email sent successfully to " + email);
                setShowModal(false);
              } catch (error) {
                toast.error("Failed to send email. Please try again.");
              }
            }}
            disabled={!validEmail}
          >
            <span style={{ color: "white" }}>Confirm</span>
          </button>
        </div>
      </Modal>
    </>
  );
}

export default function ShareAnalyticsScript({
  oneTrustCode,
  showButton = true,
  siteid,
  type,
  sampleSize,
}) {
  return (
    <>
      <ShareAnalyticsScriptComponent
        oneTrustCode={oneTrustCode}
        showButton={showButton}
        siteid={siteid}
        type={type}
        sampleSize={sampleSize}
      />
    </>
  );
}
